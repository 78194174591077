<!-- eslint-disable no-plusplus -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row
    class="match-height"
  >
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${$t('btn.edit')}`">
        <v-card-text v-if="isLoading">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="button"
              ></v-skeleton-loader>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="button"
              ></v-skeleton-loader>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
              cols="12"
              md="2"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="button"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-skeleton-loader
                class="mx-auto"
                type="table"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="backHotels()"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <template v-if="model.source.includes('hotetec') || model.source.includes('dingus')">
                <v-btn
                  v-if="!$store.state.app.onlyShow"
                  color="primary"
                  class="mb-4 me-3"
                  :disabled="disabledUpdate"
                  :loading="loading"
                  @click="showDialogSaveChannel = true"
                >
                  <span>{{ btnTitle }}</span>
                  <v-icon>{{ icons.mdiUpdate }}</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  v-if="!$store.state.app.onlyShow"
                  color="primary"
                  class="mb-4 me-3"
                  :disabled="disabledUpdate"
                  :loading="loading"
                  @click="save()"
                >
                  <span>{{ btnTitle }}</span>
                  <v-icon>{{ icons.mdiUpdate }}</v-icon>
                </v-btn>
              </template>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              v-if="model.source.includes('hotetec') || model.source.includes('dingus')"
              cols="12"
              md="2"
            >
              <!--<v-btn
                v-if="!$store.state.app.onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loadingExport"
                @click="exportData()"
              >
                <span>{{ $t('btn.export') }}</span>
                <v-icon>mdi-file-export</v-icon>
              </v-btn>-->
              <!--<vue-json-to-csv
                :json-data="[
                  { name: 'John', surname: 'Doe', age: 20, salary: 20.000, hours: 37.4 },
                  { name: 'John', surname: 'Roe', age: 40, salary: 40.000, hours: 35.2 },
                  { name: 'Jane', surname: 'Woe', age: 50, salary: 52.000, hours: 30.4 }
                ]"
                :labels="{
                  name: { title: 'First name' },
                  salary: { title: 'Annual salary' },
                  hours: { title: 'Hours/week' }
                }"
                @success="val => handleSuccess(val)"
                @error="val => handleError(val)"
              >
                <button>
                  <b>My custom button</b>
                </button>
              </vue-json-to-csv>
              <vue-blob-json-csv
                v-if="jsonExport"
                file-type="csv"
                file-name="export"
                :data="jsonExport"
              >
                <v-btn
                  color="success"
                  block
                  dark
                  large
                >
                  <span>Exportar</span>
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-btn>
              </vue-blob-json-csv>-->
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="10"
            >
              <span
                v-if="model.proveedor_id"
                class="mr-10"
              >
                <strong>{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
              </span>
              <span class="mr-10">
                <strong>{{ $t('lbl.identificador') }}</strong>: {{ model.identificador }}
              </span>
              <span>
                <strong>{{ $t('lbl.hotel') }}</strong>: {{ hotel.name }}
                <span v-if="model.source.includes('hotetec')">
                  (Code Hotetec: {{ codeHotel }})
                </span>
                <span v-else-if="model.source.includes('dingus')">
                  (Code Dingus: {{ codeHotel }})
                </span>
                <span v-else-if="!model.source.includes('dingus') && !model.source.includes('hotetec')">
                  (Code Directo: {{ codeHotel }})
                </span>
              </span>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-switch
                v-model="seasonContrate.publico"
                :label="$t('lbl.publico')"
                hide-details
                class="pt-0"
                :disabled="$store.state.app.onlyShow"
                @click="changePublico"
              ></v-switch>
            </v-col>

            <!--<v-col
              cols="12"
              md="12"
              class="text-center"
            >
              <v-row no-gutters>
                <v-col
                  cols="4"
                  class="text-right"
                >
                  <v-switch
                    v-model="seasonContrateHotel.only_adult"
                    :label="$t('lbl.only_adult')"
                    hide-details
                    class="mt-2"
                    @click="verifySeasonContrateOnlyAdult"
                  ></v-switch>
                  !--<br />
                  <strong>{{ $t('lbl.typeContrato') }}</strong>:--
                </v-col>
                !--<v-col cols="8">
                  <v-radio-group
                    v-model="season.type_contrate"
                    row
                    class="ml-4"
                  >
                    <v-radio
                      :label="$t('lbl.suplementReducc')"
                      value="suplement_reduc"
                    ></v-radio>
                    <v-radio
                      :label="$t('lbl.prices')"
                      value="prices"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>-->

            <!--<v-col
              cols="12"
              md="6"
            >
              <v-switch
                v-model="stopSale"
                :label="$t('lbl.stopSale')"
                hide-details
                class="mt-0 pt-0"
                :disabled="$store.state.app.onlyShow"
              ></v-switch>
            </v-col>-->
          </v-row>

          <!--ROOMS PLAN ALIM EDADES-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <b>{{ $t('lbl.roomsPlan') }}</b>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemRoomsPlan()"
                  >
                    <v-icon>
                      {{ showRowRoomsPlan ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowRoomsPlan">
                <template v-if="model.source.includes('dingus')">
                  <SeasonRoomsPlanAgesHotelDingus
                    v-for="(room, index) in roomsSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :hotel="hotel"
                    :plan-hotel="planHotel"
                    :room="room"
                    :planes="planes"
                    :all-rooms="allRooms"
                    :hotetec="model.source.includes('hotetec') ? true : false"
                    :dingus="model.source.includes('dingus') ? true : false"
                  />
                </template>
                <template v-else>
                  <SeasonRoomsPlanAgesHotel
                    v-for="(room, index) in roomsSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :hotel="hotel"
                    :plan-hotel="planHotel"
                    :room="room"
                    :planes="planes"
                    :all-rooms="allRooms"
                    :hotetec="model.source.includes('hotetec') ? true : false"
                    :dingus="model.source.includes('dingus') ? true : false"
                  />
                </template>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--TIPO CAMA-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <b>{{ $t('menu.tipoCamas') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowRoomsTipoCama && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addTipoCamaRoomSeasonContrateHotel()"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemRoomsTipoCama()"
                  >
                    <v-icon>
                      {{ showRowRoomsTipoCama ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowRoomsTipoCama">
                <SeasonTipoCamaHotel
                  v-for="(room, index) in tipoCamaRoomsSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :room="room"
                  :hotel="hotel"
                  :all-tipo-cama="allTipoCama"
                  :all-rooms="allRooms"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--OCUPACION MAX-->
          <v-row v-if="!model.source.includes('hotetec')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ model.source.includes('dingus') ? $t('lbl.ocupations') : $t('lbl.ocupationMax') }}</b>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemRoomsEdades()"
                  >
                    <v-icon>
                      {{ showRowRoomsEdades ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowRoomsEdades">
                <template v-if="model.source.includes('dingus')">
                  <SeasonRoomsMaxOcupationHotelDingus
                    v-for="(room, index) in maxOcupationRoomsSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :hotel="hotel"
                    :room="room"
                    :all-rooms="allRooms"
                    @changeOcupationMaxDingus="changeOcupationMaxDingus"
                  />
                </template>
                <template v-else>
                  <SeasonRoomsMaxOcupationHotel
                    v-for="(room, index) in maxOcupationRoomsSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :hotel="hotel"
                    :room="room"
                    :all-rooms="allRooms"
                  />
                </template>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="model.source.includes('hotetec')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.ocupations') }}</b>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemRoomsEdades()"
                  >
                    <v-icon>
                      {{ showRowRoomsEdades ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowRoomsEdades">
                <SeasonOcupationRoomHotel
                  v-for="(room, index) in ocupationRoomsSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :hotel="hotel"
                  :room="room"
                  :all-rooms="allRooms"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider
            class="mb-2 mt-2"
          ></v-divider>

          <!--DISPONIBILIDAD HOTETEC
          <v-row v-if="model.source.includes('hotetec')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>Tarifas</b>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemDisponibilidadHotetec()"
                  >
                    <v-icon>
                      {{ showRowDisponibilidadHotetec ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowDisponibilidadHotetec">
                <SeasonDisponibilidadHotetec
                  v-for="(room, index) in infoExtern"
                  :key="index"
                  :pos="index"
                  :hotel="hotel"
                  :room="room"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider
            v-if="model.source.includes('hotetec')"
            class="mb-2 mt-2"
          ></v-divider>-->

          <!--CATEGORIAS DE TARIFARIO-->
          <v-row v-if="model.source.includes('hotetec') || model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('menu.tarifas') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <!--<v-tooltip
                    v-if="showRowCategoriesTarifarios && tarifasHotels.length === 0 && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addTarifasContrateHotel"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>-->
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemCategoriesTarifariosHotetec()"
                  >
                    <v-icon>
                      {{ showRowCategoriesTarifarios ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowCategoriesTarifarios">
                <template v-if="tarifasHotels.length > 0">
                  <SeasonTarifas
                    v-for="(tarifa, indT) in tarifasHotels"
                    :key="indT"
                    :pos="indT"
                    :hotel="hotel"
                    :tarifa="tarifa"
                    :categories-tarifarios="categoriesTarifarios"
                  />
                </template>
              </v-row>
            </v-col>
          </v-row>
          <v-divider
            v-if="model.source.includes('hotetec') || model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--TARIFAS DIRECTO-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('menu.tarifas') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <!--<v-tooltip
                    v-if="showRowCategoriesTarifarios && tarifasDirectoHotels.length === 0 && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addTarifasContrateDirectoHotel"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>-->
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemCategoriesTarifariosHotetec()"
                  >
                    <v-icon>
                      {{ showRowCategoriesTarifarios ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowCategoriesTarifarios">
                <template v-if="tarifasDirectoHotels.length > 0">
                  <SeasonTarifasDirecto
                    v-for="(tarifa, indT) in tarifasDirectoHotels"
                    :key="indT"
                    :pos="indT"
                    :hotel="hotel"
                    :tarifa="tarifa"
                    :categories-tarifarios="categoriesTarifarios"
                  />
                </template>
              </v-row>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--TARIFARIO-->
          <template v-if="hasPrices">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <b>Tarifario</b>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-btn
                      icon
                      @click="showItemDisponibilidadHotetec()"
                    >
                      <v-icon>
                        {{ showRowDisponibilidadHotetec ? icons.mdiMenuDown : icons.mdiMenuRight }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="showRowDisponibilidadHotetec">
                  <SeasonTarifarioFilters
                    :hotel="hotel"
                    :planes="planes"
                    :all-rooms="allRooms"
                    :code-hotel="codeHotel"
                    :categories-tarifarios="categoriesTarifarios"
                  />
                  <v-col
                    v-if="loadingTarifarios"
                    cols="12"
                    md="12"
                    class="text-center"
                  >
                    <v-progress-circular
                      :size="70"
                      :width="7"
                      color="primary"
                      indeterminate
                      class="text-center"
                    ></v-progress-circular>
                  </v-col>
                  <template v-if="!loadingTarifarios">
                    <SeasonTarifario
                      v-if="tarifariosHotels.length > 0"
                      :hotel="hotel"
                      :source="model.source"
                    />

                    <v-col
                      v-if="tarifariosHotels.length === 0"
                      cols="12"
                      md="12"
                    >
                      <v-alert
                        outlined
                        color="error"
                        :icon="icons.mdiInformationOutline"
                      >
                        {{ $t('msg.noResultFiltrado') }}
                      </v-alert>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
            <v-divider
              class="mb-2 mt-2"
            ></v-divider>
          </template>

          <!--SEASONS-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.temporadas') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowSeasons && seasonsContrateHotel.length < 7 && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addSeasonContrateHotel(idH)"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemSeasons()"
                  >
                    <v-icon>
                      {{ showRowSeasons ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowSeasons">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <strong style="opacity:0;">.</strong>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          :label="$t('lbl.fromSeason')"
                          class="my-input"
                          hide-details
                          disabled
                        ></v-text-field>

                        <v-text-field
                          :label="$t('lbl.toSeason')"
                          class="my-input"
                          hide-details
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!--<v-col
                    cols="12"
                    md="1"
                  ></v-col>-->
                  <v-col
                    cols="12"
                    md="8"
                    class="ml-15"
                  >
                    <v-row>
                      <SeasonHotel
                        v-for="(season, index) in seasonsContrateHotel"
                        :key="index"
                        :pos="index"
                        :season="season"
                      />
                      <!--<v-col
                        cols="12"
                        md="12"
                      >
                        <div
                          ref="scrolled"
                          class="scrolled"
                        >
                          <div>
                            <SeasonHotel
                              v-for="(season, index) in seasonsContrateHotel"
                              :key="index"
                              :pos="index"
                              :season="season"
                            />
                          </div>
                        </div>
                      </v-col>-->
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--CUPOS-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.cupo') }}</b>
                </v-col>

                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemCupos()"
                  >
                    <v-icon>
                      {{ showRowCupos ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowCupos">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-if="seasonsContrateHotel.length > 0">
                  <!--<SeasonNamesHotel
                    :show-p-p-p="false"
                    :show-tarifa="false"
                  />-->
                  <SeasonNamesHotel8
                    :show-p-p-p="false"
                    :show-tarifa="false"
                  />
                  <SeasonCuposHotel
                    v-for="(room, index) in cuposRoomsSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :room="room"
                    :hotel="hotel"
                    :planes="planes"
                    :all-rooms="allRooms"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--RELEASE-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>Release</b>
                </v-col>

                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemRelease()"
                  >
                    <v-icon>
                      {{ showRowRelease ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowRelease">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-if="seasonsContrateHotel.length > 0">
                  <SeasonNamesHotel8
                    :show-p-p-p="false"
                    :show-tarifa="false"
                  />
                  <SeasonReleaseHotel
                    v-for="(room, index) in releaseRoomsSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :room="room"
                    :hotel="hotel"
                    :planes="planes"
                    :all-rooms="allRooms"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--ESTANCIA MINIMA-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.estanciaMin') }}</b>
                </v-col>

                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemEstanciaMin()"
                  >
                    <v-icon>
                      {{ showRowEstanciaMin ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowEstanciaMin">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-if="seasonsContrateHotel.length > 0">
                  <SeasonNamesHotel8
                    :show-p-p-p="false"
                    :show-tarifa="false"
                  />
                  <SeasonEstanciaMinHotel
                    v-for="(room, index) in estaciaMinRoomsSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :room="room"
                    :hotel="hotel"
                    :planes="planes"
                    :all-rooms="allRooms"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--ESTANCIA MAXIMA-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.estanciaMax') }}</b>
                </v-col>

                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemEstanciaMax()"
                  >
                    <v-icon>
                      {{ showRowEstanciaMax ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowEstanciaMax">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-if="seasonsContrateHotel.length > 0">
                  <SeasonNamesHotel8
                    :show-p-p-p="false"
                    :show-tarifa="false"
                  />
                  <SeasonEstanciaMaxHotel
                    v-for="(room, index) in estaciaMaxRoomsSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :room="room"
                    :hotel="hotel"
                    :planes="planes"
                    :all-rooms="allRooms"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--PRECIOS DE ESTANCIA DBL-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.estanciaDbl') }}</b>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemPricesEstancia()"
                  >
                    <v-icon>
                      {{ showRowPricesEstancia ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowPricesEstancia">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    pricesDBLRoomsSeasonContrateHotel.length > 0 &&
                      seasonsContrateHotel.length > 0
                  "
                >
                  <SeasonNamesHotel8
                    :show-p-p-p="true"
                    :show-tarifa="false"
                  />
                  <SeasonPricesHotel
                    v-for="(room, index) in pricesDBLRoomsSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :room="room"
                    :hotel="hotel"
                    :all-rooms="allRooms"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--SUPLEMENTOS / REDUCCIONES / TARIFAS-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.suplRedTarif') }}</b>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemSuplementos()"
                  >
                    <v-icon>
                      {{ showRowSuplementos ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowSuplementos">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <SeasonSuplementsReduccionsTarifasHotel
                    v-for="(suplement, index) in suplementsReduccTarifasSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :suplement="suplement"
                    :all-suplement="allSuplement"
                    :all-rooms="allRooms"
                    :only-adult="onlyAdult"
                    :all-include="allInclude"
                    :hotel="hotel"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--POLITICA DE NIÑOS-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.polityChild') }}</b>
                </v-col>

                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemPolityChild()"
                  >
                    <v-icon>
                      {{ showRowPolityChild ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowPolityChild">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <SeasonPoliticaChildHotel
                    v-for="(politica, index) in politicasChildSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :politica="politica"
                    :all-rooms="allRooms"
                    :only-adult="onlyAdult"
                    :all-include="allInclude"
                    :hotel="hotel"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--SUPLEMENTOS ALIMENTICIOS-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.suplementsAlimenticios') }}</b>
                </v-col>

                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    :disabled="disabledPlan"
                    @click="showItemSuplementosAlimentos()"
                  >
                    <v-icon>
                      {{ showRowSuplementosAlimentos ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowSuplementosAlimentos">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <SeasonSuplementsAlimenticiosHotel
                    v-for="(suplement, index) in suplementsAlimenticiosSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :suplement="suplement"
                    :all-suplement="allSuplement"
                    :only-adult="onlyAdult"
                    :all-include="allInclude"
                    :hotel="hotel"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--OTROS SUPLEMENTOS-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.suplementsOtros') }}</b>
                </v-col>

                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemSuplementosOtros()"
                  >
                    <v-icon>
                      {{ showRowSuplementosOtros ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowSuplementosOtros">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <SeasonSuplementsOtrosHotel
                    v-for="(suplement, index) in suplementsOtrosSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :suplement="suplement"
                    :all-suplement="allSuplement"
                    :all-rooms="allRooms"
                    :only-adult="onlyAdult"
                    :all-include="allInclude"
                    :hotel="hotel"
                    :all-planes="planes"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--OFERTAS ESPECIALES--
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.ofertSpecial') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowOfertasSpecial && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addOfertaSpecialSeasonContrateHotel"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemOfertasSpecial()"
                  >
                    <v-icon>
                      {{ showRowOfertasSpecial ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowOfertasSpecial">
                <SeasonOfertasSpecialHotel
                  v-for="(oferta, index) in ofertasSpecialSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :oferta="oferta"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>-->

          <!--COMPRA ANTICIPADA--
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.saleAnticip') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowOfertas && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addOfertaSeasonContrateHotel"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemOfertas()"
                  >
                    <v-icon>
                      {{ showRowOfertas ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowOfertas">
                <SeasonOfertasHotel
                  v-for="(oferta, index) in ofertasSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :oferta="oferta"
                  :all-suplement="allSuplement"
                  :all-reductions="allReduccion"
                  :all-rooms="allRooms"
                  :only-adult="onlyAdult"
                  :all-include="allInclude"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>-->

          <!--CANCELACIONES Y NO SHOW-->
          <v-row v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')">
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.cancelaciones') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowNotShow && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addNotShowSeasonContrateHotel()"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>

                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemNotShow()"
                  >
                    <v-icon>
                      {{ showRowNotShow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <template v-if="showRowNotShow">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="tarifasDirectoHotels.length > 0"
                      v-model="tarifaSelect"
                      :disabled="$store.state.app.onlyShow"
                      :items="tarifasDirectoHotels"
                      :label="$t('lbl.tarifa')"
                      class="my-input mr-2"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      @click.native="changeTarifaDirectClick"
                      @change="changeTarifaDirect"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.tarifa') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <SeasonNotShowHotel
                    v-for="(notShow, index) in notShowSeasonContrateHotel"
                    :key="index"
                    :pos="index"
                    :not-show="notShow"
                    :all-rooms="allRooms"
                    :hotel="hotel"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-divider
            v-if="!model.source.includes('hotetec') && !model.source.includes('dingus')"
            class="mb-2 mt-2"
          ></v-divider>

          <!--CONTACTS-->
          <v-row v-if="contacts.length > 0">
            <v-col
              cols="12"
              md="12"
            >
              <v-row class="mt-0">
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.contacts') }}</b>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemContacts()"
                  >
                    <v-icon>
                      {{ showRowContacts ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowContacts">
                <template v-if="contactFree.length > 0">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="contactAdd"
                      :disabled="$store.state.app.onlyShow"
                      :items="contactFree"
                      :label="$t('lbl.contact')"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="id"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.contact') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ `${item.name} (${emailsItem(item.emails)})` }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-tooltip
                      v-if="!$store.state.app.onlyShow"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mt-1 mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="addNewContact()"
                        >
                          <v-icon small>
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.insert') }}</span>
                    </v-tooltip>
                  </v-col>
                </template>
                <template v-if="contacts_proveedor.length > 0">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-uppercase">
                              {{ $t('lbl.name') }}
                            </th>
                            <!--<th class="text-uppercase">
                              {{ $t('menu.roles') }}
                            </th>-->
                            <th class="text-uppercase">
                              {{ $t('lbl.email') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('lbl.telefono') }}
                            </th>
                            <th
                              width="150px"
                              class="text-uppercase"
                            >
                              {{ $t('lbl.actions') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <fragment
                            v-for="(contact, indP) in contactContrateHotel"
                            :key="indP"
                          >
                            <tr>
                              <td>{{ contact.name }}</td>
                              <!--<td>
                                <span v-if="contact.role_id">
                                  <span v-if="rols.filter(e => e.id === contact.role_id).length > 0">
                                    {{ rols.filter(e => e.id === contact.role_id)[0].name }}
                                  </span>
                                </span>
                              </td>-->
                              <td>
                                <span
                                  v-for="(email, indEm) in contact.emails"
                                  :key="indEm"
                                >
                                  {{ email.email }}<br />
                                </span>
                              </td>
                              <td>
                                <span
                                  v-for="(telefono, indEm) in contact.phones"
                                  :key="indEm"
                                >
                                  {{ telefono.code ? `+${telefono.code}` : '' }} {{ telefono.phone }}

                                  <span v-if="telefono.ext">
                                    <span v-if="telefono.ext !== null && telefono.ext !== 'null' && telefono.ext !== ''">
                                      Ext.: {{ telefono.ext }}
                                    </span>
                                  </span>
                                  <span v-if="telefono.exts">
                                    <span v-if="telefono.exts.length > 0">
                                      <span
                                        v-for="(ext, indE) in telefono.exts"
                                        :key="indE"
                                      >
                                        <span v-if="ext.ext !== null && ext.ext !== 'null' && ext.ext !== ''">
                                          <b v-if="indE === 0">Ext.:</b>
                                          {{ ext.ext }}<br />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <br />
                                </span>
                              </td>
                              <td>
                                <v-tooltip
                                  v-if="!$store.state.app.onlyShow"
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="deleteContact(indP)"
                                    >
                                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('btn.delete') }}</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </fragment>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
          <v-divider
            v-if="contacts.length > 0"
            class="mb-2 mt-2"
          ></v-divider>

          <!--<v-row v-if="!noTieneCategCondicionesGenerales">
            <v-col
              cols="12"
              md="12"
            >
              <v-row class="mt-0">
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('menu.conditionGenerals') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemConditions()"
                  >
                    <v-icon>
                      {{ showRowConditions ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-expansion-panels v-if="showRowConditions">
            <fragment v-if="categCondicionesGenerales.length > 0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="mb-4"
                >
                  <v-text-field
                    v-model="filtro"
                    :label="$t('lbl.reference')"
                    :disabled="$store.state.app.onlyShow"
                    outlined
                    dense
                    hide-details
                    @input="filtrarCateCondicionesGenerales()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-expansion-panel
                v-for="(cate, ind) in categCondicionesGenerales"
                :key="ind"
              >
                <v-expansion-panel-header v-if="cate.condicionesGenerales.length > 0">
                  {{ cate.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <fragment
                    v-for="(cond, indCond) in cate.condicionesGenerales"
                    :key="indCond"
                  >
                    <fragment v-if="cond.show">
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-checkbox
                            v-model="condicionesGenerales_id"
                            :disabled="$store.state.app.onlyShow"
                            :label="`${cond.name ? `${cond.name}` : ''}`"
                            :value="cond.id"
                            hide-details
                            class="pt-0"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                        >
                          <v-btn
                            icon
                            @click="showItem(indCond)"
                          >
                            <v-icon>
                              {{ showRow && indCond === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <div
                            class="col-12"
                            :class="showRow && indCond === indexRow ? '' : 'text-truncate'"
                          >
                            <p v-html="cond.description"></p>
                          </div>
                        </v-col>
                      </v-row>
                    </fragment>
                  </fragment>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </fragment>
          </v-expansion-panels> -->
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- CAMBIAR TARIFA -->
    <v-dialog
      v-model="showDialogChangeTarifa"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('lbl.infoImportant')}!!!` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.saveChangesTarifa') }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            :disabled="loadingModal"
            @click="canceleChangeTarifa()"
          >
            {{ $t('btn.descartar') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingModal"
            @click="confirmChangeTarifa()"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--CHANGE BACK-->
    <v-dialog
      v-model="showDialogChangeTarifaBack"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('lbl.infoImportant')}!!!` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.saveChangesCambios') }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            :disabled="loadingModal"
            @click="descartarBackHotels()"
          >
            {{ $t('btn.descartar') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingModal"
            @click="confirmBackHotels()"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SAVE WITH CHANNEL -->
    <v-dialog
      v-model="showDialogSaveChannel"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('lbl.infoImportant')}!!!` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-text>
            ¿Está seguro que desea guardar estos cambios?
            <br />
            <br />
            Esta acción es irreversible y podría afectar a los precios de este contrato.
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            :disabled="loadingModal"
            @click="showDialogSaveChannel = !showDialogSaveChannel"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingModal"
            @click="save()"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- STOP SALE -->
    <v-dialog
      v-model="showDialogPublico"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('lbl.publico') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-text>
            {{ seasonContrate.publico ? $t('msg.cancelePublico') : $t('msg.aceptPublico') }}
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="canceleChangePublico()"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingModal"
            @click="changePublicoConfirm()"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading
      v-if="loadingComponent"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiInformationOutline,
} from '@mdi/js'
import Vue2Filters from 'vue2-filters'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import SeasonRoomsPlanAgesHotel from '../utils/SeasonRoomsPlanAgesHotel.vue'
import SeasonRoomsPlanAgesHotelDingus from '../utils/SeasonRoomsPlanAgesHotelDingus.vue'
import SeasonTipoCamaHotel from '../utils/SeasonTipoCamaHotel.vue'
// eslint-disable-next-line no-undef
import SeasonCuposHotel from '../utils/SeasonCuposHotel.vue'
import SeasonRoomsMaxOcupationHotel from '../utils/SeasonRoomsMaxOcupationHotel.vue'
import SeasonRoomsMaxOcupationHotelDingus from '../utils/SeasonRoomsMaxOcupationHotelDingus.vue'
import SeasonOcupationRoomHotel from '../utils/SeasonOcupationRoomHotel.vue'
import SeasonDisponibilidadHotetec from '../utils/SeasonDisponibilidadHotetec.vue'
import SeasonTarifas from '../utils/SeasonTarifas.vue'
import SeasonTarifasDirecto from '../utils/SeasonTarifasDirecto.vue'
import SeasonTarifario from '../utils/SeasonTarifario.vue'
import SeasonTarifarioFilters from '../utils/SeasonTarifarioFilters.vue'
import SeasonHotel from '../utils/SeasonHotel.vue'
import SeasonNamesHotel from '../utils/SeasonNamesHotel.vue'
import SeasonNamesHotel8 from '../utils/SeasonNamesHotel8.vue'
import SeasonReleaseHotel from '../utils/SeasonReleaseHotel.vue'
import SeasonEstanciaMinHotel from '../utils/SeasonEstanciaMinHotel.vue'
import SeasonEstanciaMaxHotel from '../utils/SeasonEstanciaMaxHotel.vue'
import SeasonPricesHotel from '../utils/SeasonPricesHotel.vue'
import SeasonSuplementsReduccionsTarifasHotel from '../utils/SeasonSuplementsReduccionsTarifasHotel.vue'
import SeasonSuplementsAlimenticiosHotel from '../utils/SeasonSuplementsAlimenticiosHotel.vue'
import SeasonSuplementsOtrosHotel from '../utils/SeasonSuplementsOtrosHotel.vue'
import SeasonPoliticaChildHotel from '../utils/SeasonPoliticaChildHotel.vue'
import SeasonNotShowHotel from '../utils/SeasonNotShowHotel.vue'

// import SeasonReduccionsHotel from '../utils/SeasonReduccionsHotel.vue'
// import SeasonAcomodationRoom from '../utils/SeasonAcomodationRoom.vue'
// import SeasonRangeAgeRoom from '../utils/SeasonRangeAgeRoom.vue'

// import SeasonOfertasHotel from '../utils/SeasonOfertasHotel.vue'
// import SeasonOfertasSpecialHotel from '../utils/SeasonOfertasSpecialHotel.vue'

// eslint-disable-next-line no-unused-vars, import/no-unresolved

export default {
  components: {
    AppCardCode,
    SeasonRoomsPlanAgesHotel,
    SeasonRoomsPlanAgesHotelDingus,
    SeasonOcupationRoomHotel,
    SeasonCuposHotel,
    SeasonRoomsMaxOcupationHotel,
    SeasonRoomsMaxOcupationHotelDingus,
    // eslint-disable-next-line vue/no-unused-components
    SeasonDisponibilidadHotetec,
    SeasonTarifasDirecto,
    SeasonTarifas,
    SeasonTarifario,
    SeasonTarifarioFilters,
    // eslint-disable-next-line vue/no-unused-components
    SeasonHotel,
    // eslint-disable-next-line vue/no-unused-components
    SeasonNamesHotel,
    SeasonNamesHotel8,
    SeasonReleaseHotel,
    SeasonEstanciaMinHotel,
    SeasonEstanciaMaxHotel,
    SeasonPricesHotel,
    SeasonSuplementsReduccionsTarifasHotel,
    SeasonSuplementsAlimenticiosHotel,
    SeasonSuplementsOtrosHotel,
    SeasonPoliticaChildHotel,
    SeasonNotShowHotel,
    SeasonTipoCamaHotel,
    Loading,

    // SeasonReduccionsHotel,
    // SeasonAcomodationRoom,
    // SeasonRangeAgeRoom,

    // SeasonOfertasHotel,
    // SeasonOfertasSpecialHotel,
  },
  mixins: [Vue2Filters.mixin],
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      showDialogSaveChannel: false,
      showDialogPublico: false,
      hasPrices: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiInformationOutline,
      },
      loading: false,
      loadingSeason: true,
      pagination: {
        current: 1,
        total: 0,
      },
      model: {
        source: [],
      },
      hotel: {},
      season: {
        type_contrate: 'suplement_reduc',
        only_adult: false,
      },
      onlyAdult: false,
      allInclude: false,
      planHotel: null,
      cantRooms: 0,
      itemsPerPage: 10,
      contacts: [],
      contactsAll: [],
      products: [],
      contacts_proveedor: [],
      contacts_hotel: [],
      hotelsNomAll: [],
      allSuplement: [],
      allRooms: [],
      allTipoCama: [],
      allReduccion: [],
      permisos: localStorage.getItem('permisos_auth'),
      idH: localStorage.getItem('hotels-id'),
      showRowRoomsPlan: false,
      showRowRoomsTipoCama: false,
      showRowRoomsEdades: false,
      showRowDisponibilidadHotetec: false,
      showRowCategoriesTarifarios: false,
      showRowContacts: false,
      showRowConditions: false,
      showRowSeasons: false,
      showRowCupos: false,
      showRowRelease: false,
      showRowEstanciaMin: false,
      showRowEstanciaMax: false,
      showRowPricesEstancia: false,
      showRowSuplementos: false,
      showRowPolityChild: false,
      showRowNotShow: false,
      showRowSuplementosAlimentos: false,
      showRowSuplementosOtros: false,
      showRowReducciones: false,
      showRowAcomodation: false,
      showRowRangoAge: false,
      showRowOfertas: false,
      showRowOfertasSpecial: false,
      typeProductHotel: 0,
      planes: [],
      rols: [],
      contactAdd: null,
      categCondicionesGenerales: [],
      condicionesGenerales: [],
      noTieneCategCondicionesGenerales: true,
      condicionesGenerales_id: [],
      showCategories: false,
      showRow: false,
      indexRow: -1,
      filtro: null,
      stopSale: false,
      datesStopSale: [],
      edadHotelOnlyAdult: 0,
      codeHotel: null,
      infoExtern: [],
      tarifarios: [],
      seasonOrigin: null,
      categoriesTarifarios: [],
      tarifaSelect: -1,
      tarifaSelectOld: -1,
      showDialogChangeTarifa: false,
      showDialogChangeTarifaBack: false,
      loadingExport: false,
      jsonExport: null,
      loadingModal: false,
      loadingComponent: false,
      jsonOrigin: {},
      jsonBack: {},
      seasonContrate: {
        publico: false,
      },
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      seasonContrateHotel: state => state.app.seasonContrateHotel,
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      tipoCamaRoomsSeasonContrateHotel: state => state.app.tipoCamaRoomsSeasonContrateHotel,
      maxOcupationRoomsSeasonContrateHotel: state => state.app.maxOcupationRoomsSeasonContrateHotel,
      ocupationRoomsSeasonContrateHotel: state => state.app.ocupationRoomsSeasonContrateHotel,
      cuposRoomsSeasonContrateHotel: state => state.app.cuposRoomsSeasonContrateHotel,
      releaseRoomsSeasonContrateHotel: state => state.app.releaseRoomsSeasonContrateHotel,
      estaciaMinRoomsSeasonContrateHotel: state => state.app.estaciaMinRoomsSeasonContrateHotel,
      estaciaMaxRoomsSeasonContrateHotel: state => state.app.estaciaMaxRoomsSeasonContrateHotel,
      pricesDBLRoomsSeasonContrateHotel: state => state.app.pricesDBLRoomsSeasonContrateHotel,
      suplementsSeasonContrateHotel: state => state.app.suplementsSeasonContrateHotel,
      suplementsReduccTarifasSeasonContrateHotel: state => state.app.suplementsReduccTarifasSeasonContrateHotel,
      suplementsAlimenticiosSeasonContrateHotel: state => state.app.suplementsAlimenticiosSeasonContrateHotel,
      suplementsOtrosSeasonContrateHotel: state => state.app.suplementsOtrosSeasonContrateHotel,
      politicasChildSeasonContrateHotel: state => state.app.politicasChildSeasonContrateHotel,
      notShowSeasonContrateHotel: state => state.app.notShowSeasonContrateHotel,
      reduccionsSeasonContrateHotel: state => state.app.reduccionsSeasonContrateHotel,
      rangeAgeContrateHotel: state => state.app.rangeAgeContrateHotel,
      ofertasSeasonContrateHotel: state => state.app.ofertasSeasonContrateHotel,
      ofertasSpecialSeasonContrateHotel: state => state.app.ofertasSpecialSeasonContrateHotel,
      loadingTarifarios: state => state.app.loadingTarifarios,
      tarifariosHotels: state => state.app.tarifariosHotels,
      tarifasHotels: state => state.app.tarifasHotels,
      tarifasDirectoHotels: state => state.app.tarifasDirectoHotels,
      tarifariosOrigin: state => state.app.tarifariosOrigin,
      tarifaSelectDirectoHotels: state => state.app.tarifaSelectDirectoHotels,
    }),
    showAddCupos() {
      if (this.roomsSeasonContrateHotel.length < this.cantRooms) {
        return true
      }

      return false
    },
    contactContrateHotel() {
      const arr = []

      this.contacts_proveedor.forEach(element => {
        arr.push(element)
      })

      return arr
    },
    contactFree() {
      const arr = []
      this.contactsAll.forEach(contac => {
        let esta = false
        this.contacts_proveedor.forEach(element => {
          if (element.id === contac.id) {
            esta = true
          }
        })
        if (!esta) {
          let tiene = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < contac.emails.length; index++) {
            if (contac.emails[index].email !== null) {
              tiene = true
            }
          }
          if (tiene) {
            arr.push(contac)
          }
        }
      })

      /* this.contacts.forEach(contac => {
        if (!this.contacts_proveedor.includes(contac.id)) {
          arr.push(contac)
        }

        * if (contac.products.includes(this.typeProductHotel)) {
          if (!this.contacts_proveedor.includes(contac.id)) {
            arr.push(contac)
          }
        } *
      }) */

      return this.orderBy(arr, 'name')
    },
    disabledPlan() {
      let disabled = true

      let idTI = 0
      this.planes.forEach(element => {
        if (element.siglas === 'TI') {
          idTI = element.id
        }
      })

      if (this.roomsSeasonContrateHotel.length > 0) {
        this.roomsSeasonContrateHotel.forEach(element => {
          if (element.plan_id !== idTI) {
            disabled = false
          }
        })
      }

      return disabled
    },
    disabledUpdate() {
      let disabled = true

      let jsonUpdate = {}

      jsonUpdate = JSON.stringify({
        seasons: {
          rooms: this.roomsSeasonContrateHotel,
          tipo_cama: this.tipoCamaRoomsSeasonContrateHotel,
          range_age: this.maxOcupationRoomsSeasonContrateHotel,
          ocupations: this.ocupationRoomsSeasonContrateHotel,
          tarifas: this.tarifasHotels,
          tarifasDirecto: this.tarifasDirectoHotels,
        },
        contacts_proveedor: this.contacts_proveedor,
        conditions: this.condicionesGenerales_id.length === 0 ? null : this.condicionesGenerales_id,
      })

      if (this.jsonOrigin !== jsonUpdate) {
        disabled = false
      }

      return disabled
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true

    // this.getProducts()
  },
  created() {
    this.fetchDataFromEndpoints()
    if (localStorage.getItem('hotel-contrato-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }

    // this.getRols()
    // this.getItem()
    // this.getSuplements()
    // this.getRooms()
    // this.getTipoCama()
    // this.getReduccions()
    // this.getCategoriesTarifarios()
  },

  methods: {
    ...mapMutations([
      'setOnlyShow',
      'verifySeasonContrateOnlyAdult',
      'addSeasonContrateHotel',
      'addRoomSeasonContrateHotel',
      'addTipoCamaRoomSeasonContrateHotel',
      'addCupoRoomSeasonContrateHotel',
      'addReleaseRoomSeasonContrateHotel',
      'addPriceDBLRoomsNullSeasonContrateHotel',
      'addPriceDBLRoomsSeasonContrateHotel',
      'addEstanciaMinRoomSeasonContrateHotel',
      'addEstanciaMaxRoomSeasonContrateHotel',
      'addSuplementsReduccTarifasSeasonContrateHotel',
      'addSuplementsAlimenticiosSeasonContrateHotel',
      'addSuplementsOtrosSeasonContrateHotel',
      'addSuplementSeasonContrateHotel',
      'addReduccionSeasonContrateHotel',
      'addOfertaSeasonContrateHotel',
      'addOfertaSpecialSeasonContrateHotel',
      'addNotShowSeasonContrateHotel',
      'addTarifasContrateHotel',
      'addTarifasContrateDirectoHotel',
      'updateSeasonContrateHotel',
      'updateRoomSeasonContrateHotel',
      'updateSuplementSeasonContrateHotel',
      'updateReduccionSeasonContrateHotel',
      'updateRangeAgeContrateHotel',
      'updateOfertaSeasonContrateHotel',
      'updateOfertaSpecialSeasonContrateHotel',
      'verifyRoomsPriceDBL',
      'updateOnlyAdult',
      'updateAllInclude',
      'addMaxOcupationRoomsSeasonContrateHotel',
      'updateRangeAgeRoomsSeasonContrateHotel',
      'updateOcupationRoomsSeasonContrateHotel',
      'updateCupoRoomSeasonContrateHotel',
      'updateReleaseRoomSeasonContrateHotel',
      'updateEstanciaMinRoomSeasonContrateHotel',
      'updateEstanciaMaxRoomSeasonContrateHotel',
      'updatePriceDBLRoomsSeasonContrateHotel',
      'updatePoliticasChildSeasonContrateHotel',
      'updateSuplementsReduccTarifasSeasonContrateHotel',
      'updateSuplementsAlimenticiosSeasonContrateHotel',
      'updateSuplementsOtrosSeasonContrateHotel',
      'updateNotShowSeasonContrateHotel',
      'updateTarifariosContrateHotel',
      'updateTarifariosOriginContrateHotel',
      'updateTarifasContrateHotel',
      'updateTarifasContrateDirectoHotel',
      'updateTarifaSelectDirectoHotel',
      'reajustPoliticasChildSeasonContrateHotel',
      'updateTarifariosOrigin',
      'updateSeasonContrateHotelByOne',
      'updateTipoCamaRoomSeasonContrateHotel',
    ]),
    async fetchDataFromEndpoints() {
      let id = 0
      const idHotel = localStorage.getItem('hotels-id')
      if (localStorage.getItem('hotels-contrate-id')) {
        id = localStorage.getItem('hotels-contrate-id')
      } else {
        this.$router.push({ name: 'hotels-contrate-list' })
      }

      try {
        const [
          resTypeProduct,
          resRoles,
          resItem,
          resPlans,
          resConditions,
          resCateConditions,
          resHotel,
          resSuplement,
          resRooms,
          resTipoCama,
          resReductions,
          resCateTarifarios,
        ] = await Promise.all([
          this.axios
            .get('type_product?per_page=1000', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get('role?per_page=10000', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`contrate_hotels/${id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_regimen_alimenticio?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get('nom_conditions_generals?for_hotel=1&per_page=10000', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get('nom_categories_conditions?for_hotel=1&per_page=100000', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`hotels/${idHotel}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_suplemento_contrate/hotel?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_tipo_cama_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_reducciones_contrate/hotel?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(
              'nom_categories_tarifarios_hotels?per_page=10000',
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            ),
        ])

        resTypeProduct.data.data.forEach(element => {
          if (element.slug === 'hotels') {
            this.typeProductHotel = element.id
          }
        })

        // eslint-disable-next-line no-empty
        if (resRoles.data.success === false) { } else {
          this.rols = resRoles.data.data
        }

        this.model = resItem.data.data.data
        if (this.model.proveedor_id !== null && this.model.proveedor_id !== 'null') {
          if (this.model.proveedor.contacts !== null && this.model.proveedor.contacts !== 'null') {
            this.contacts = this.model.proveedor.contacts

            this.contacts.forEach(element => {
              this.contactsAll.push({
                proveedor: true,
                hotel: false,
                ...element,
              })
            })
          }
        }

        this.planes = resPlans.data.data

        // eslint-disable-next-line no-empty
        if (resConditions.data.success === false) { } else {
          this.condicionesGenerales = resConditions.data.data
        }

        // eslint-disable-next-line no-empty
        if (resCateConditions.data.success === false) { } else {
          this.categCondicionesGenerales = []

          // this.allCategCondicionesGenerales = res.data.data
          resCateConditions.data.data.forEach(element => {
            const conditions = []
            this.condicionesGenerales.forEach(cond => {
              if (cond.category_id === element.id && this.model.proveedor_id === cond.proveedor_id) {
                conditions.push({
                  ...cond,
                  show: true,
                })
              }
            })
            this.categCondicionesGenerales.push({
              ...element,
              filtro: null,
              condicionesGenerales: conditions,
            })
          })
        }

        this.hotel = resHotel.data.data.data
        this.getSeasons()
        if (this.hotel.contactos) {
          this.contacts_hotel = this.hotel.contactos

          this.contacts_hotel.forEach(element => {
            this.contactsAll.push({
              proveedor: false,
              hotel: true,
              ...element,
            })
          })
        }
        this.cantRooms = this.hotel.rooms_id.length
        if (this.hotel.only_adult === 1) {
          this.onlyAdult = true
          if (this.hotel.edad_min) {
            if (this.hotel.edad_min.edad) {
              this.edadHotelOnlyAdult = this.hotel.edad_min.edad
            }
          }
          this.updateOnlyAdult(true)
        } else {
          this.updateOnlyAdult(false)
        }
        if (this.hotel.all_include === 1) {
          this.allInclude = true
          this.updateAllInclude(true)
        } else {
          this.updateAllInclude(false)
        }
        if (this.hotel.all_include) {
          this.planes.forEach(element => {
            if (element.siglas.toLowerCase() === 'ti') {
              this.planHotel = element.id
            }
          })
        } else {
          this.planes.forEach(element => {
            if (element.siglas.toLowerCase() === 'cp') {
              this.planHotel = element.id
            }
          })
        }

        this.allSuplement = resSuplement.data.data

        this.allRooms = resRooms.data.data

        this.allTipoCama = resTipoCama.data.data

        this.allReduccion = resReductions.data.data

        // eslint-disable-next-line no-empty
        if (resCateTarifarios.data.success === false) { } else {
          this.categoriesTarifarios = resCateTarifarios.data.data
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      } finally {
        this.isLoadingHotels = false
        this.isLoading = false

        setTimeout(() => {
          this.checkConditionsGeneral()
        }, 500)
      }
    },
    changeOcupationMaxDingus() {
      if (localStorage.getItem('hotels-contrate-temporada_id') !== null) {
        // eslint-disable-next-line camelcase
        const temporada_id = localStorage.getItem('hotels-contrate-temporada_id')
        const json = {
          contrate_id: localStorage.getItem('hotels-contrate-id'),
          hotels: localStorage.getItem('hotels-id'),
          temporada_id,
          contacts_proveedor: this.contacts_proveedor,
          seasons: {
            range_age: this.maxOcupationRoomsSeasonContrateHotel,
          },
          code_hotel_externo: this.codeHotel,
        }
        this.axios
          .post('contrate_hotels/season-change-ocupations-dingus', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === true) {
              this.updateRangeAgeRoomsSeasonContrateHotel(response.data.data.data)
            }
          })
      }
    },
    emailsItem(emails) {
      let result = ''

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < emails.length; index++) {
        if (emails[index].email != null) {
          result += emails[index].email
          if (index !== emails.length - 1) {
            result += ', '
          }
        }
      }

      return result
    },
    changeTarifaDirect() {
      if (this.tarifaSelect) {
        // VER SI ES PRIMERA VES Q SE GUARDARA ALGO
        if (this.tarifariosOrigin !== null) {
          // DETECTAR SI EXISTIO ALGUN CAMBIO EN LA TARIFA
          const origin = JSON.parse(this.tarifariosOrigin)

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < origin.length; index++) {
            if (origin[index].code === this.tarifaSelectOld) {
              // eslint-disable-next-line no-unused-vars
              const jsonOrigin = {
                category_id: origin[index].category_id,
                category_slug: origin[index].category_slug,
                code: origin[index].code,
                cupos: origin[index].cupos,
                estancia_min: origin[index].estancia_min,
                estancia_max: origin[index].estancia_max,
                name: origin[index].name,
                not_show: origin[index].not_show,
                politicas_childs: origin[index].politicas_childs,
                price_estancia_dbl: origin[index].price_estancia_dbl,
                is_tarifa_price_estancia_dbl: origin[index].is_tarifa_price_estancia_dbl,
                price_in_dbl: origin[index].price_in_dbl,
                release: origin[index].release,
                seasons: origin[index].seasons,
                suplements_alimenticios: origin[index].suplements_alimenticios,
                suplements_otros: origin[index].suplements_otros,
                suplements_reductions: origin[index].suplements_reductions,
              }

              // eslint-disable-next-line no-unused-vars
              const jsonUpdate = {
                category_id: this.tarifasDirectoHotels[index].category_id,
                category_slug: this.tarifasDirectoHotels[index].category_slug,
                code: this.tarifasDirectoHotels[index].code,
                cupos: this.cuposRoomsSeasonContrateHotel,
                estancia_min: this.estaciaMinRoomsSeasonContrateHotel,
                estancia_max: this.estaciaMaxRoomsSeasonContrateHotel,
                name: this.tarifasDirectoHotels[index].name,
                not_show: this.notShowSeasonContrateHotel,
                politicas_childs: this.politicasChildSeasonContrateHotel,
                price_estancia_dbl: this.seasonContrateHotel.price_estancia_dbl,
                is_tarifa_price_estancia_dbl: this.seasonContrateHotel.is_tarifa_price_estancia_dbl,
                price_in_dbl: this.pricesDBLRoomsSeasonContrateHotel,
                release: this.releaseRoomsSeasonContrateHotel,
                seasons: this.seasonsContrateHotel,
                suplements_alimenticios: this.suplementsAlimenticiosSeasonContrateHotel,
                suplements_otros: this.suplementsOtrosSeasonContrateHotel,
                suplements_reductions: this.suplementsReduccTarifasSeasonContrateHotel,
              }

              if (JSON.stringify(jsonOrigin) === JSON.stringify(jsonUpdate)) {
                this.canceleChangeTarifa()
              } else {
                this.showDialogChangeTarifa = true
              }
            }
          }
        } else {
          this.showDialogChangeTarifa = true
        }
      }

      // this.tarifaSelectOld = this.tarifaSelectDirectoHotels
    },
    changeTarifaDirectClick() {
      this.tarifaSelectOld = this.tarifaSelect
    },
    canceleChangeTarifa() {
      let pos = -1
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.tarifasDirectoHotels.length; index++) {
        if (this.tarifasDirectoHotels[index].code === this.tarifaSelect) {
          pos = index
        }
      }

      // ACTUALIZAR TARIFA
      this.changeTarifaDescartar(pos)

      this.showDialogChangeTarifa = false
    },
    asigValuesJsonTarifa(pos) {
      this.tarifasDirectoHotels[pos].seasons = this.seasonsContrateHotel
      this.tarifasDirectoHotels[pos].cupos = this.cuposRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].release = this.releaseRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].estancia_min = this.estaciaMinRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].estancia_max = this.estaciaMaxRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].price_estancia_dbl = this.seasonContrateHotel.price_estancia_dbl
      this.tarifasDirectoHotels[pos].is_tarifa_price_estancia_dbl = this.seasonContrateHotel.is_tarifa_price_estancia_dbl
      this.tarifasDirectoHotels[pos].price_in_dbl = this.pricesDBLRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].politicas_childs = this.politicasChildSeasonContrateHotel
      this.tarifasDirectoHotels[pos].suplements_reductions = this.suplementsReduccTarifasSeasonContrateHotel
      this.tarifasDirectoHotels[pos].suplements_alimenticios = this.suplementsAlimenticiosSeasonContrateHotel
      this.tarifasDirectoHotels[pos].suplements_otros = this.suplementsOtrosSeasonContrateHotel
      this.tarifasDirectoHotels[pos].not_show = this.notShowSeasonContrateHotel
    },
    changeTarifaDescartar(pos) {
      if (localStorage.getItem('hotels-id') !== null) {
        const json = {
          contrate_id: localStorage.getItem('hotels-contrate-id'),
          hotel_id: localStorage.getItem('hotels-id'),
        }

        this.loadingComponent = true
        this.axios
          .post('contrate_hotels/list-season', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // eslint-disable-next-line no-empty
            if (!res.data.success) {} else {
              if (res.data.data.infoExterna) {
                this.infoExtern = res.data.data.infoExterna
              }

              if (res.data.data.contacts_proveedor.length > 0) {
                this.contacts_proveedor = res.data.data.contacts_proveedor
              }
              if (res.data.data.seasons) {
                if (res.data.data.seasons.tarifasDirecto) {
                  this.updateTarifasContrateDirectoHotel(res.data.data.seasons.tarifasDirecto)

                  if (res.data.data.seasons.tarifasDirecto.length > 0) {
                    const tar = res.data.data.seasons.tarifasDirecto[pos]

                    this.updateSeasons(tar)
                  }
                } else {
                  this.updateTarifasContrateDirectoHotel([])
                }

                this.updateRoomSeasonContrateHotel(res.data.data.seasons.rooms)
                this.updateRangeAgeRoomsSeasonContrateHotel(res.data.data.seasons.range_age)
              } else {
                // RESETEAR LOS ARRAY
                this.updateSeasonContrateHotel([])
                this.updateRoomSeasonContrateHotel([])
                this.updateRangeAgeRoomsSeasonContrateHotel([])
                this.updateCupoRoomSeasonContrateHotel([])
                this.updateReleaseRoomSeasonContrateHotel([])
                this.updateOcupationRoomsSeasonContrateHotel([])
                this.updateEstanciaMinRoomSeasonContrateHotel([])
                this.updateEstanciaMaxRoomSeasonContrateHotel([])
                this.updatePriceDBLRoomsSeasonContrateHotel([])
                this.updatePoliticasChildSeasonContrateHotel([])
                this.updateSuplementsReduccTarifasSeasonContrateHotel([])
                this.updateSuplementsAlimenticiosSeasonContrateHotel([])
                this.updateSuplementsOtrosSeasonContrateHotel([])
                this.updateNotShowSeasonContrateHotel([])
                this.updateTarifasContrateHotel([])
                this.updateTarifasContrateDirectoHotel([])

                // INICIALIZAR LOS ARRAY
                this.addRoomSeasonContrateHotel(this.planHotel)
                this.addMaxOcupationRoomsSeasonContrateHotel()
                this.addCupoRoomSeasonContrateHotel()
                this.addEstanciaMinRoomSeasonContrateHotel()
                this.addEstanciaMaxRoomSeasonContrateHotel()
                this.addReleaseRoomSeasonContrateHotel()
                this.addSuplementsReduccTarifasSeasonContrateHotel()
                this.addSuplementsAlimenticiosSeasonContrateHotel()
                this.addSuplementsOtrosSeasonContrateHotel()
              }
            }
          })
          .finally(() => {
            this.loadingComponent = false
          })
      }
    },
    clearSeasons() {
      this.updateSeasonContrateHotel([])
      this.updateCupoRoomSeasonContrateHotel([])
      this.updateReleaseRoomSeasonContrateHotel([])
      this.updateEstanciaMinRoomSeasonContrateHotel([])
      this.updateEstanciaMaxRoomSeasonContrateHotel([])
      this.updateSuplementsOtrosSeasonContrateHotel([])
      this.updateSuplementsReduccTarifasSeasonContrateHotel([])
      this.updatePoliticasChildSeasonContrateHotel([])
      this.updateSuplementsAlimenticiosSeasonContrateHotel([])

      // this.updatePriceDBLRoomsSeasonContrateHotel([])
    },
    updateSeasons(tar) {
      // TEMPORADAS
      if (tar.seasons) {
        if (tar.seasons.length > 0) {
          this.updateSeasonContrateHotel(tar.seasons)
        } else {
          this.updateSeasonContrateHotel([])
        }
      } else {
        this.updateSeasonContrateHotel([])
      }

      // CUPOS
      if (tar.cupos) {
        if (tar.cupos.length > 0) {
          this.updateCupoRoomSeasonContrateHotel(tar.cupos)
        } else {
          this.addCupoRoomSeasonContrateHotel()
        }
      } else {
        this.addCupoRoomSeasonContrateHotel()
      }

      // RELEASE
      if (tar.release) {
        if (tar.release.length > 0) {
          this.updateReleaseRoomSeasonContrateHotel(tar.release)
        } else {
          this.addReleaseRoomSeasonContrateHotel()
        }
      } else {
        this.addReleaseRoomSeasonContrateHotel()
      }

      // ESTANCIA MINIMA
      if (tar.estancia_min) {
        if (tar.estancia_min.length > 0) {
          this.updateEstanciaMinRoomSeasonContrateHotel(tar.estancia_min)
        } else {
          this.addEstanciaMinRoomSeasonContrateHotel()
        }
      } else {
        this.addEstanciaMinRoomSeasonContrateHotel()
      }

      // ESTANCIA MAXIMA
      if (tar.estancia_max) {
        if (tar.estancia_max.length > 0) {
          this.updateEstanciaMaxRoomSeasonContrateHotel(tar.estancia_max)
        } else {
          this.addEstanciaMaxRoomSeasonContrateHotel()
        }
      } else {
        this.addEstanciaMaxRoomSeasonContrateHotel()
      }

      // PRECIO EN HAB DBL
      this.updateSeasonContrateHotelByOne({
        price_estancia_dbl: tar.price_estancia_dbl ? tar.price_estancia_dbl : this.seasonContrateHotel.price_estancia_dbl,
        is_tarifa_price_estancia_dbl: tar.is_tarifa_price_estancia_dbl,
      })
      if (tar.price_in_dbl) {
        if (tar.price_in_dbl.length > 0) {
          // VERIFICAR Q SE HALLAN MODIFICADO LAS HABS
          if (tar.price_in_dbl.length === this.pricesDBLRoomsSeasonContrateHotel.length) {
            this.updatePriceDBLRoomsSeasonContrateHotel(tar.price_in_dbl)
          } else {
            this.verifyRoomsPriceDBL()
          }
        } else {
          this.verifyRoomsPriceDBL()
        }
      } else {
        this.verifyRoomsPriceDBL()
      }

      // POLITICA DE CHAMAS
      if (tar.politicas_childs) {
        this.updatePoliticasChildSeasonContrateHotel(tar.politicas_childs)
      }

      // REDUCCIONES / SUPLEMENTOS
      if (tar.suplements_reductions) {
        if (tar.suplements_reductions.length > 0) {
          this.updateSuplementsReduccTarifasSeasonContrateHotel(tar.suplements_reductions)
        } else {
          this.addSuplementsReduccTarifasSeasonContrateHotel()
        }
      } else {
        this.addSuplementsReduccTarifasSeasonContrateHotel()
      }

      // SUPLEMENTOS ALIMENTICIOS
      if (tar.suplements_alimenticios) {
        if (tar.suplements_alimenticios.length > 0) {
          this.updateSuplementsAlimenticiosSeasonContrateHotel(tar.suplements_alimenticios)
        } else {
          this.addSuplementsAlimenticiosSeasonContrateHotel()
        }
      } else {
        this.addSuplementsAlimenticiosSeasonContrateHotel()
      }

      // OTROS SUPLEMENTOS
      if (tar.suplements_otros) {
        if (tar.suplements_otros.length > 0) {
          this.updateSuplementsOtrosSeasonContrateHotel(tar.suplements_otros)
        } else {
          this.addSuplementsOtrosSeasonContrateHotel()
        }
      } else {
        this.addSuplementsOtrosSeasonContrateHotel()
      }

      // CANCELACIONES Y NOT SHOW
      if (tar.not_show) {
        if (tar.not_show.length > 0) {
          this.updateNotShowSeasonContrateHotel(tar.not_show)
        } else {
          this.updateNotShowSeasonContrateHotel([])
        }
      } else {
        this.updateNotShowSeasonContrateHotel([])
      }
    },
    confirmChangeTarifa() {
      // eslint-disable-next-line camelcase
      let temporada_id = uuidv4()
      if (localStorage.getItem('hotels-contrate-temporada_id') !== null) {
        // eslint-disable-next-line camelcase
        temporada_id = localStorage.getItem('hotels-contrate-temporada_id')
      }

      let pos = -1
      let posNew = -1
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.tarifasDirectoHotels.length; index++) {
        if (this.tarifasDirectoHotels[index].code === this.tarifaSelectOld) {
          pos = index
        }

        if (this.tarifasDirectoHotels[index].code === this.tarifaSelect) {
          posNew = index
        }
      }

      // ACTUALIZAR TARIFA
      this.asigValuesJsonTarifa(pos)

      const json = {
        contrate_id: localStorage.getItem('hotels-contrate-id'),
        hotels: localStorage.getItem('hotels-id'),
        temporada_id,
        contacts_proveedor: this.contacts_proveedor,
        seasons: {
          rooms: this.roomsSeasonContrateHotel,
          range_age: this.maxOcupationRoomsSeasonContrateHotel,
          ocupations: this.ocupationRoomsSeasonContrateHotel,
          tarifas: this.tarifasHotels,
          tarifasDirecto: this.tarifasDirectoHotels,

          // seasons: this.seasonsContrateHotel,
          // cupos: this.cuposRoomsSeasonContrateHotel,
          // release: this.releaseRoomsSeasonContrateHotel,
          // estancia_min: this.estaciaMinRoomsSeasonContrateHotel,
          // type_estancia_dbl: this.seasonContrateHotel.price_estancia_dbl,
          // is_tarifa_price_estancia_dbl: this.seasonContrateHotel.is_tarifa_price_estancia_dbl,
          // price_in_dbl: this.pricesDBLRoomsSeasonContrateHotel,
          // politicas_childs: this.politicasChildSeasonContrateHotel,
          // suplements_reductions: this.suplementsReduccTarifasSeasonContrateHotel,
          // suplements_alimenticios: this.suplementsAlimenticiosSeasonContrateHotel,
          // suplements_otros: this.suplementsOtrosSeasonContrateHotel,
          // not_show: this.notShowSeasonContrateHotel,
          // tarifas: this.tarifasHotels,
        },
        type_contrate: this.season.type_contrate,

        // publico: !this.stopSale,
        conditions: this.condicionesGenerales_id,
        code_hotel_externo: this.codeHotel,
      }

      const proceder = true

      if (proceder) {
        this.loadingModal = true
        this.loadingComponent = true
        this.axios
          .post('contrate_hotels/season-save', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
            } else {
              localStorage.setItem('hotels-contrate-temporada_id', response.data.data.temporada_id)

              // PARA COMPARAR SI EXISTIO ALGUN CAMBIO
              this.updateTarifariosOrigin(JSON.stringify(response.data.data.data.seasons.tarifasDirecto))

              // LIMPIAR ARRAY DE SEASONS
              this.clearSeasons()

              const tar = this.tarifasDirectoHotels[posNew]

              // ASIGNAR VALORES DE LA TARIFA ACTIVA
              this.updateSeasons(tar)
              this.updateTarifaSelectDirectoHotel(this.tarifaSelect)
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loadingModal = false
            this.loadingComponent = false
          })
      }

      this.updateTarifaSelectDirectoHotel(this.tarifaSelect)

      this.showDialogChangeTarifa = false
    },
    verifyHasPrice() {
      this.axios
        .post('contrate_hotels/list-season-has-prices', { code: this.codeHotel }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            if (res.data.data > 0) {
              this.hasPrices = true
            }
          }
        })
    },
    showItem(indCond) {
      if (this.indexRow === indCond) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = indCond
    },
    checkConditionsGeneral() {
      this.noTieneCategCondicionesGenerales = true
      this.categCondicionesGenerales.forEach(cate => {
        if (cate.condicionesGenerales.length > 0) {
          this.noTieneCategCondicionesGenerales = false
        }
      })
    },
    filtrarCateCondicionesGenerales() {
      if (this.filtro) {
        // eslint-disable-next-line no-plusplus
        for (let pos = 0; pos < this.categCondicionesGenerales.length; pos++) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.categCondicionesGenerales[pos].condicionesGenerales.length; index++) {
            if (this.categCondicionesGenerales[pos].condicionesGenerales[index].name) {
              if (
                this.categCondicionesGenerales[pos].condicionesGenerales[index].name.toLowerCase().includes(this.filtro)
              ) {
                this.categCondicionesGenerales[pos].condicionesGenerales[index].show = true
              } else {
                this.categCondicionesGenerales[pos].condicionesGenerales[index].show = false
              }
            } else {
              this.categCondicionesGenerales[pos].condicionesGenerales[index].show = false
            }
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let pos = 0; pos < this.categCondicionesGenerales.length; pos++) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.categCondicionesGenerales[pos].condicionesGenerales.length; index++) {
            this.categCondicionesGenerales[pos].condicionesGenerales[index].show = true
          }
        }
      }
    },

    backHotels() {
      let jsonUpdate = {}

      // this.asigDataTarifa()

      jsonUpdate = JSON.stringify({
        seasons: {
          rooms: this.roomsSeasonContrateHotel,
          tipo_cama: this.tipoCamaRoomsSeasonContrateHotel,
          range_age: this.maxOcupationRoomsSeasonContrateHotel,
          ocupations: this.ocupationRoomsSeasonContrateHotel,
          tarifas: this.tarifasHotels,
          tarifasDirecto: this.tarifasDirectoHotels,
        },
        contacts_proveedor: this.contacts_proveedor,
        conditions: this.condicionesGenerales_id.length === 0 ? null : this.condicionesGenerales_id,
      })

      if (this.jsonOrigin !== jsonUpdate) {
        this.showDialogChangeTarifaBack = true
      } else {
        this.descartarBackHotels()
      }
    },
    descartarBackHotels() {
      this.showDialogChangeTarifaBack = false
      localStorage.removeItem('hotels-show')
      localStorage.removeItem('hotels-id')
      localStorage.removeItem('hotels-contrate-temporada_id')
      this.$router.push({ name: 'hotels-contrate-update' })
    },
    asigDataTarifa() {
      let pos = -1
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.tarifasDirectoHotels.length; index++) {
        if (this.tarifasDirectoHotels[index].code === this.tarifaSelect) {
          pos = index
        }
      }

      this.tarifasDirectoHotels[pos].seasons = this.seasonsContrateHotel
      this.tarifasDirectoHotels[pos].cupos = this.cuposRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].release = this.releaseRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].estancia_min = this.estaciaMinRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].estancia_max = this.estaciaMaxRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].price_estancia_dbl = this.seasonContrateHotel.price_estancia_dbl
      this.tarifasDirectoHotels[pos].is_tarifa_price_estancia_dbl = this.seasonContrateHotel.is_tarifa_price_estancia_dbl
      this.tarifasDirectoHotels[pos].price_in_dbl = this.pricesDBLRoomsSeasonContrateHotel
      this.tarifasDirectoHotels[pos].politicas_childs = this.politicasChildSeasonContrateHotel
      this.tarifasDirectoHotels[pos].suplements_reductions = this.suplementsReduccTarifasSeasonContrateHotel
      this.tarifasDirectoHotels[pos].suplements_alimenticios = this.suplementsAlimenticiosSeasonContrateHotel
      this.tarifasDirectoHotels[pos].suplements_otros = this.suplementsOtrosSeasonContrateHotel
      this.tarifasDirectoHotels[pos].not_show = this.notShowSeasonContrateHotel
    },
    confirmBackHotels() {
      // eslint-disable-next-line camelcase
      let temporada_id = uuidv4()
      if (localStorage.getItem('hotels-contrate-temporada_id') !== null) {
        // eslint-disable-next-line camelcase
        temporada_id = localStorage.getItem('hotels-contrate-temporada_id')
      }

      if (!this.model.source.includes('hotetec') && !this.model.source.includes('dingus')) {
        this.asigDataTarifa()
      }

      const json = {
        contrate_id: localStorage.getItem('hotels-contrate-id'),
        hotels: localStorage.getItem('hotels-id'),
        temporada_id,
        contacts_proveedor: this.contacts_proveedor,
        seasons: {
          rooms: this.roomsSeasonContrateHotel,
          range_age: this.maxOcupationRoomsSeasonContrateHotel,
          ocupations: this.ocupationRoomsSeasonContrateHotel,
          tarifas: this.tarifasHotels,
          tarifasDirecto: this.tarifasDirectoHotels,

          // seasons: this.seasonsContrateHotel,
          // cupos: this.cuposRoomsSeasonContrateHotel,
          // release: this.releaseRoomsSeasonContrateHotel,
          // estancia_min: this.estaciaMinRoomsSeasonContrateHotel,
          // type_estancia_dbl: this.seasonContrateHotel.price_estancia_dbl,
          // is_tarifa_price_estancia_dbl: this.seasonContrateHotel.is_tarifa_price_estancia_dbl,
          // price_in_dbl: this.pricesDBLRoomsSeasonContrateHotel,
          // politicas_childs: this.politicasChildSeasonContrateHotel,
          // suplements_reductions: this.suplementsReduccTarifasSeasonContrateHotel,
          // suplements_alimenticios: this.suplementsAlimenticiosSeasonContrateHotel,
          // suplements_otros: this.suplementsOtrosSeasonContrateHotel,
          // not_show: this.notShowSeasonContrateHotel,
          // tarifas: this.tarifasHotels,
        },
        type_contrate: this.season.type_contrate,

        // publico: !this.stopSale,
        conditions: this.condicionesGenerales_id,
        code_hotel_externo: this.codeHotel,
      }

      const proceder = true

      if (proceder) {
        this.loadingModal = true
        this.loadingComponent = true
        this.axios
          .post('contrate_hotels/season-save', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
            } else {
              localStorage.setItem('hotels-contrate-temporada_id', response.data.data.temporada_id)
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loadingModal = false
            this.loadingComponent = false

            this.showDialogChangeTarifa = false

            localStorage.removeItem('hotels-show')
            localStorage.removeItem('hotels-id')
            localStorage.removeItem('hotels-contrate-temporada_id')
            this.$router.push({ name: 'hotels-contrate-update' })
          })
      }
    },
    changePublico() {
      this.showDialogPublico = true
    },
    canceleChangePublico() {
      this.showDialogPublico = !this.showDialogPublico
      this.seasonContrate.publico = !this.seasonContrate.publico
    },
    generarCodeHotel() {
      this.codeHotel = `${this.model.proveedor.name_comercial.slice(0, 3).toUpperCase()}${Math.random()
        .toString(36)
        .slice(-5)
        .toUpperCase()}`
    },

    save() {
      // eslint-disable-next-line camelcase
      let temporada_id = uuidv4()
      if (localStorage.getItem('hotels-contrate-temporada_id') !== null) {
        // eslint-disable-next-line camelcase
        temporada_id = localStorage.getItem('hotels-contrate-temporada_id')
      }
      let pos = -1
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.tarifasDirectoHotels.length; index++) {
        if (this.tarifasDirectoHotels[index].code === this.tarifaSelect) {
          pos = index
        }
      }

      // ACTUALIZAR TARIFA
      if (!this.model.source.includes('hotetec') && !this.model.source.includes('dingus')) {
        if (pos !== -1) {
          this.asigValuesJsonTarifa(pos)
        }
      }

      const json = {
        contrate_id: localStorage.getItem('hotels-contrate-id'),
        hotels: localStorage.getItem('hotels-id'),
        temporada_id,
        contacts_proveedor: this.contacts_proveedor,
        seasons: {
          rooms: this.roomsSeasonContrateHotel,
          tipo_cama: this.tipoCamaRoomsSeasonContrateHotel,
          range_age: this.maxOcupationRoomsSeasonContrateHotel,
          ocupations: this.ocupationRoomsSeasonContrateHotel,
          tarifas: this.tarifasHotels,
          tarifasDirecto: this.tarifasDirectoHotels,

          // seasons: this.seasonsContrateHotel,
          // cupos: this.cuposRoomsSeasonContrateHotel,
          // release: this.releaseRoomsSeasonContrateHotel,
          // estancia_min: this.estaciaMinRoomsSeasonContrateHotel,
          // type_estancia_dbl: this.seasonContrateHotel.price_estancia_dbl,
          // is_tarifa_price_estancia_dbl: this.seasonContrateHotel.is_tarifa_price_estancia_dbl,
          // price_in_dbl: this.pricesDBLRoomsSeasonContrateHotel,
          // politicas_childs: this.politicasChildSeasonContrateHotel,
          // suplements_reductions: this.suplementsReduccTarifasSeasonContrateHotel,
          // suplements_alimenticios: this.suplementsAlimenticiosSeasonContrateHotel,
          // suplements_otros: this.suplementsOtrosSeasonContrateHotel,
          // not_show: this.notShowSeasonContrateHotel,
          // tarifas: this.tarifasHotels,
        },
        type_contrate: this.season.type_contrate,
        publico: this.season.publico,
        conditions: this.condicionesGenerales_id,
        code_hotel_externo: this.codeHotel,
      }

      const proceder = true

      if (proceder) {
        this.loadingComponent = true
        this.loading = true
        this.axios
          .post('contrate_hotels/season', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
            } else {
              this.$toast.success(this.$t('msg.saveTarifas', { n: this.hotel.name, m: this.model.identificador }))

              /* localStorage.removeItem('hotels-show')
              localStorage.removeItem('hotels-id')
              localStorage.removeItem('hotels-contrate-temporada_id')
              this.$router.push({ name: 'hotels-contrate-update' })
              localStorage.removeItem('hotels-contrate-temporada_id') */
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loadingComponent = false
            this.loading = false
            this.showDialogSaveChannel = false
          })
      }
    },
    changePublicoConfirm() {
      this.loadingModal = true
      this.axios
        .post('contrate_hotels/season-change-public', this.seasonContrate, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loadingModal = false
          this.showDialogPublico = false
        })
    },
    deleteContact(id) {
      this.contacts_proveedor.splice(id, 1)

      /* const ind = this.contacts_proveedor.indexOf(id)

      if (ind > -1) {
        this.contacts_proveedor.splice(ind, 1)
      } */
    },
    addNewContact() {
      if (this.contactAdd) {
        const cont = this.contactsAll.filter(e => e.id === this.contactAdd)[0]
        this.contacts_proveedor.push(cont)
        this.contactAdd = null
      }
    },

    showItemRoomsPlan() {
      this.showRowRoomsPlan = !this.showRowRoomsPlan
      this.showRowRoomsTipoCama = false
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false
    },
    showItemRoomsEdades() {
      this.showRowRoomsEdades = !this.showRowRoomsEdades
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false
    },
    showItemDisponibilidadHotetec() {
      this.showRowDisponibilidadHotetec = !this.showRowDisponibilidadHotetec
      this.showRowCategoriesTarifarios = false
      this.showRowRoomsEdades = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false
    },
    showItemCategoriesTarifariosHotetec() {
      this.showRowCategoriesTarifarios = !this.showRowCategoriesTarifarios
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsEdades = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false
    },
    showItemSeasons() {
      this.showRowSeasons = !this.showRowSeasons
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false

      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemCupos() {
      this.showRowCupos = !this.showRowCupos
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false
      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemRelease() {
      this.showRowRelease = !this.showRowRelease
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false

      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemEstanciaMin() {
      this.showRowEstanciaMin = !this.showRowEstanciaMin
      this.showRowEstanciaMax = false
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false

      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemEstanciaMax() {
      this.showRowEstanciaMax = !this.showRowEstanciaMax
      this.showRowEstanciaMin = false
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false

      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemPricesEstancia() {
      this.showRowPricesEstancia = !this.showRowPricesEstancia
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false

      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemSuplementos() {
      this.showRowSuplementos = !this.showRowSuplementos
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false

      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemPolityChild() {
      this.showRowPolityChild = !this.showRowPolityChild
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false
      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemNotShow() {
      this.showRowNotShow = !this.showRowNotShow
      this.showRowPolityChild = false
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false
    },
    showItemSuplementosAlimentos() {
      this.showRowSuplementosAlimentos = !this.showRowSuplementosAlimentos
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false

      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemSuplementosOtros() {
      this.showRowSuplementosOtros = !this.showRowSuplementosOtros
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowContacts = false

      if (this.tarifaSelect === -1) {
        this.tarifaSelect = this.tarifaSelectDirectoHotels
      }
    },
    showItemContacts() {
      this.showRowContacts = !this.showRowContacts
      this.showRowSuplementosOtros = false
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowRoomsPlan = false
      this.showRowRoomsTipoCama = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowRoomsTipoCama = false
    },
    showItemRoomsTipoCama() {
      this.showRowRoomsTipoCama = !this.showRowRoomsTipoCama
      this.showRowRoomsPlan = false
      this.showRowRoomsEdades = false
      this.showRowCategoriesTarifarios = false
      this.showRowDisponibilidadHotetec = false
      this.showRowSeasons = false
      this.showRowCupos = false
      this.showRowRelease = false
      this.showRowEstanciaMin = false
      this.showRowEstanciaMax = false
      this.showRowPricesEstancia = false
      this.showRowSuplementos = false
      this.showRowPolityChild = false
      this.showRowNotShow = false
      this.showRowSuplementosAlimentos = false
      this.showRowSuplementosOtros = false
      this.showRowContacts = false
    },

    exportData() {
      const json = {
        contrate_id: localStorage.getItem('hotels-contrate-id'),
        hotel_id: localStorage.getItem('hotels-id'),
        source: this.model.source[0],
      }

      this.loadingExport = true

      this.axios
        .post('contrate_hotels/export-season-codes', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          this.jsonExport = response.data.data

          let csv = 'Código Tarifa,Nombre Tarifa\n'
          this.jsonExport.tarifas.forEach(row => {
            csv += `${row.code},`
            csv += `${row.name},`
            csv += '\n'
          })

          const anchor = document.createElement('a')
          anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
          anchor.target = '_blank'
          anchor.download = 'Tarifas.csv'
          anchor.click()

          let csv2 = 'Código Hab.,Nombre Hab.\n'
          this.jsonExport.rooms.forEach(row => {
            csv2 += `${row.code},`
            csv2 += `${row.name},`
            csv2 += '\n'
          })

          const anchor2 = document.createElement('a')
          anchor2.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv2)}`
          anchor2.target = '_blank'
          anchor2.download = 'Hab.csv'
          anchor2.click()

          let csv3 = 'Código Plan,Nombre Plan\n'
          this.jsonExport.planes.forEach(row => {
            csv3 += `${row.code},`
            csv3 += `${row.name},`
            csv3 += '\n'
          })

          const anchor3 = document.createElement('a')
          anchor3.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv3)}`
          anchor3.target = '_blank'
          anchor3.download = 'Planes.csv'
          anchor3.click()
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loadingExport = false
        })
    },

    getSeasons() {
      if (localStorage.getItem('hotels-id') !== null) {
        const json = {
          contrate_id: localStorage.getItem('hotels-contrate-id'),
          hotel_id: localStorage.getItem('hotels-id'),
        }
        this.axios
          .post('contrate_hotels/list-season', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (!res.data.success) {
              // RESETEAR LOS ARRAY
              this.clearSeasons()
              this.updateRangeAgeRoomsSeasonContrateHotel([])
              this.updateTipoCamaRoomSeasonContrateHotel([])
              this.updateRoomSeasonContrateHotel([])
              this.updateTarifariosContrateHotel([])
              this.updateTarifasContrateHotel([])
              this.updateTarifasContrateDirectoHotel([])
              this.updateTarifariosOriginContrateHotel([])

              // INICIALIZAR LOS ARRAY
              this.addRoomSeasonContrateHotel(this.planHotel)
              this.addMaxOcupationRoomsSeasonContrateHotel()
              this.addCupoRoomSeasonContrateHotel()
              this.addEstanciaMinRoomSeasonContrateHotel()
              this.addEstanciaMaxRoomSeasonContrateHotel()
              this.addReleaseRoomSeasonContrateHotel()
              this.addSuplementsReduccTarifasSeasonContrateHotel()
              this.addSuplementsAlimenticiosSeasonContrateHotel()
              this.addSuplementsOtrosSeasonContrateHotel()

              // if (this.model.source.includes('hotetec') || this.model.source.includes('dingus')) {
              if (res.data.data.code_hotel_externo) {
                this.codeHotel = res.data.data.code_hotel_externo
              }

              // }
            } else {
              // PARA SABER SI SE MODIFICO ALGO
              this.jsonOrigin = JSON.stringify({
                seasons: res.data.data.seasons,
                contacts_proveedor: res.data.data.contacts_proveedor,
                conditions: res.data.data.conditions,
              })

              this.seasonContrate = {
                id: res.data.data.id,
                publico: res.data.data.publico,
              }

              if (res.data.data.infoExterna) {
                this.infoExtern = res.data.data.infoExterna
              }
              if (res.data.data.tarifarios) {
                this.updateTarifariosContrateHotel(res.data.data.tarifarios)
                this.updateTarifariosOriginContrateHotel(res.data.data.tarifarios)
              } else {
                this.updateTarifariosContrateHotel([])
                this.updateTarifariosOriginContrateHotel([])
              }

              if (res.data.data.seasons) {
                if (res.data.data.contacts_proveedor.length > 0) {
                  this.contacts_proveedor = res.data.data.contacts_proveedor
                }
                if (res.data.data.seasons.tipo_cama) {
                  this.updateTipoCamaRoomSeasonContrateHotel(res.data.data.seasons.tipo_cama)
                } else {
                  this.updateTipoCamaRoomSeasonContrateHotel([])
                }
                if (res.data.data.seasons.tarifas) {
                  this.updateTarifasContrateHotel(res.data.data.seasons.tarifas)
                } else {
                  this.updateTarifasContrateHotel([])
                }
                if (res.data.data.seasons.tarifasDirecto) {
                  this.updateTarifasContrateDirectoHotel(res.data.data.seasons.tarifasDirecto)

                  if (res.data.data.seasons.tarifasDirecto.length > 0) {
                    // PARA COMPARAR SI EXISTIO ALGUN CAMBIO EN LAS TARIFAS PARA LOS CTTO DIRECTOS
                    this.updateTarifariosOrigin(JSON.stringify(res.data.data.seasons.tarifasDirecto))

                    const tarrr = res.data.data.seasons.tarifasDirecto
                    this.tarifaSelect = tarrr[0].code
                    this.updateTarifaSelectDirectoHotel(this.tarifaSelect)
                    this.updateSeasonContrateHotel(tarrr[0].seasons)

                    const tar = tarrr[0]

                    this.updateSeasons(tar)
                  }
                } else {
                  this.updateTarifasContrateDirectoHotel([])
                }

                if (this.model.source.includes('hotetec')) {
                  this.seasonOrigin = JSON.stringify({
                    rooms: res.data.data.seasons.rooms,
                    contacts: this.contacts_proveedor,
                    tarifas: this.tarifasHotels,
                  })
                }
                this.updateRoomSeasonContrateHotel(res.data.data.seasons.rooms)
                if (this.model.source.includes('hotetec')) {
                  this.updateRangeAgeRoomsSeasonContrateHotel(res.data.data.seasons.ocupations)
                } else {
                  this.updateRangeAgeRoomsSeasonContrateHotel(res.data.data.seasons.range_age)
                }

                this.updateOcupationRoomsSeasonContrateHotel(res.data.data.seasons.ocupations)

                /* this.updateSeasonContrateHotel(res.data.data.seasons.seasons)
                this.updateRoomSeasonContrateHotel(res.data.data.seasons.rooms)
                this.updateRangeAgeRoomsSeasonContrateHotel(res.data.data.seasons.range_age)
                this.updateCupoRoomSeasonContrateHotel(res.data.data.seasons.cupos)
                this.updateReleaseRoomSeasonContrateHotel(res.data.data.seasons.release)
                this.updateEstanciaMinRoomSeasonContrateHotel(res.data.data.seasons.estancia_min)
                this.seasonContrateHotel.price_estancia_dbl = res.data.data.seasons.type_estancia_dbl
                this.seasonContrateHotel.is_tarifa_price_estancia_dbl = res.data.data.seasons.is_tarifa_price_estancia_dbl
                this.updatePriceDBLRoomsSeasonContrateHotel(res.data.data.seasons.price_in_dbl)
                this.updatePoliticasChildSeasonContrateHotel(res.data.data.seasons.politicas_childs)
                this.updateSuplementsReduccTarifasSeasonContrateHotel(res.data.data.seasons.suplements_reductions)
                this.updateSuplementsAlimenticiosSeasonContrateHotel(res.data.data.seasons.suplements_alimenticios)
                this.updateSuplementsOtrosSeasonContrateHotel(res.data.data.seasons.suplements_otros)
                if (res.data.data.seasons.not_show) {
                  this.updateNotShowSeasonContrateHotel(res.data.data.seasons.not_show)
                } else {
                  this.updateNotShowSeasonContrateHotel([])
                }
                if (res.data.data.seasons.ocupations) {
                  this.updateOcupationRoomsSeasonContrateHotel(res.data.data.seasons.ocupations)
                } else {
                  this.updateOcupationRoomsSeasonContrateHotel([])
                } */

                // if (this.model.source.includes('hotetec') || this.model.source.includes('dingus')) {
                if (res.data.data.code_hotel_externo) {
                  this.codeHotel = res.data.data.code_hotel_externo
                }

                // }
              } else {
                // RESETEAR LOS ARRAY
                this.updateSeasonContrateHotel([])
                this.updateRoomSeasonContrateHotel([])
                this.updateRangeAgeRoomsSeasonContrateHotel([])
                this.updateTipoCamaRoomSeasonContrateHotel([])
                this.updateCupoRoomSeasonContrateHotel([])
                this.updateReleaseRoomSeasonContrateHotel([])
                this.updateOcupationRoomsSeasonContrateHotel([])
                this.updateEstanciaMinRoomSeasonContrateHotel([])
                this.updateEstanciaMaxRoomSeasonContrateHotel([])
                this.updatePriceDBLRoomsSeasonContrateHotel([])
                this.updatePoliticasChildSeasonContrateHotel([])
                this.updateSuplementsReduccTarifasSeasonContrateHotel([])
                this.updateSuplementsAlimenticiosSeasonContrateHotel([])
                this.updateSuplementsOtrosSeasonContrateHotel([])
                this.updateNotShowSeasonContrateHotel([])
                this.updateTarifasContrateHotel([])
                this.updateTarifasContrateDirectoHotel([])

                // INICIALIZAR LOS ARRAY
                this.addRoomSeasonContrateHotel(this.planHotel)
                this.addMaxOcupationRoomsSeasonContrateHotel()
                this.addCupoRoomSeasonContrateHotel()
                this.addEstanciaMinRoomSeasonContrateHotel()
                this.addEstanciaMaxRoomSeasonContrateHotel()
                this.addReleaseRoomSeasonContrateHotel()
                this.addSuplementsReduccTarifasSeasonContrateHotel()
                this.addSuplementsAlimenticiosSeasonContrateHotel()
                this.addSuplementsOtrosSeasonContrateHotel()

                // if (this.model.source.includes('hotetec') || this.model.source.includes('dingus')) {
                if (res.data.data.code_hotel_externo) {
                  this.codeHotel = res.data.data.code_hotel_externo
                }

                // }
              }

              if (res.data.data.contacts_proveedor.length > 0) {
                this.contacts_proveedor = res.data.data.contacts_proveedor
              }

              this.stopSale = res.data.data.stopSale
              if (res.data.data.conditions) {
                this.condicionesGenerales_id = res.data.data.conditions
              } else {
                this.condicionesGenerales_id = []
              }

              localStorage.setItem('hotels-contrate-temporada_id', res.data.data.temporada_id)
            }
          })
          .finally(() => {
            this.verifyHasPrice()
            this.isLoading = false
          })
      }
    },

    /*
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          res.data.data.forEach(element => {
            if (element.slug === 'hotels') {
              this.typeProductHotel = element.id
            }
          })
        })
    },
    getRols() {
      this.axios
        .get('role?per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.rols = res.data.data
          }
        })
    },
    getItem() {
      if (localStorage.getItem('hotels-contrate-id') !== null) {
        const id = localStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.proveedor_id !== null && this.model.proveedor_id !== 'null') {
              if (this.model.proveedor.contacts !== null && this.model.proveedor.contacts !== 'null') {
                this.contacts = this.model.proveedor.contacts

                this.contacts.forEach(element => {
                  this.contactsAll.push({
                    proveedor: true,
                    hotel: false,
                    ...element,
                  })
                })
              }
            }
          })
          .finally(() => {
            this.getPlans()
            setTimeout(() => {
              this.getCondiciones()
            }, 100)
          })
      } else {
        this.$router.push({ name: 'hotels-contrate-list' })
      }
    },
    getPlans() {
      this.axios
        .get(`nom_regimen_alimenticio?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.planes = res.data.data
        })
        .finally(() => {
          this.getHotel()
        })
    },
    getHotel() {
      if (localStorage.getItem('hotels-id') !== null) {
        const id = localStorage.getItem('hotels-id')
        this.axios
          .get(`hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.hotel = res.data.data.data
            if (this.hotel.contactos) {
              this.contacts_hotel = this.hotel.contactos

              this.contacts_hotel.forEach(element => {
                this.contactsAll.push({
                  proveedor: false,
                  hotel: true,
                  ...element,
                })
              })
            }
            this.cantRooms = this.hotel.rooms_id.length
            if (this.hotel.only_adult === 1) {
              this.onlyAdult = true
              if (this.hotel.edad_min) {
                if (this.hotel.edad_min.edad) {
                  this.edadHotelOnlyAdult = this.hotel.edad_min.edad
                }
              }
              this.updateOnlyAdult(true)
            } else {
              this.updateOnlyAdult(false)
            }
            if (this.hotel.all_include === 1) {
              this.allInclude = true
              this.updateAllInclude(true)
            } else {
              this.updateAllInclude(false)
            }
            if (this.hotel.all_include) {
              this.planes.forEach(element => {
                if (element.siglas.toLowerCase() === 'ti') {
                  this.planHotel = element.id
                }
              })
            } else {
              this.planes.forEach(element => {
                if (element.siglas.toLowerCase() === 'cp') {
                  this.planHotel = element.id
                }
              })
            }
          })
          .finally(() => {
            this.getSeasons()
          })
      }
    },
    getCondiciones() {
      this.axios
        .get('nom_conditions_generals?for_hotel=1&per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.condicionesGenerales = res.data.data
          }
        })
        .finally(() => this.getCategCondiciones())
    },
    getCategCondiciones() {
      this.axios
        .get('nom_categories_conditions?for_hotel=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categCondicionesGenerales = []

            // this.allCategCondicionesGenerales = res.data.data
            res.data.data.forEach(element => {
              const conditions = []
              this.condicionesGenerales.forEach(cond => {
                if (cond.category_id === element.id && this.model.proveedor_id === cond.proveedor_id) {
                  conditions.push({
                    ...cond,
                    show: true,
                  })
                }
              })
              this.categCondicionesGenerales.push({
                ...element,
                filtro: null,
                condicionesGenerales: conditions,
              })

              setTimeout(() => {
                this.checkConditionsGeneral()
              }, 100)
            })
          }
        })
    },
    getSuplements() {
      this.axios
        .get(`nom_suplemento_contrate/hotel?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allSuplement = res.data.data
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allRooms = res.data.data
        })
    },
    getTipoCama() {
      this.axios
        .get(`nom_tipo_cama_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allTipoCama = res.data.data
        })
    },
    getReduccions() {
      this.axios
        .get(`nom_reducciones_contrate/hotel?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allReduccion = res.data.data
        })
    },
    getCategoriesTarifarios() {
      this.axios
        .get(
          'nom_categories_tarifarios_hotels?per_page=10000',
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.categoriesTarifarios = res.data.data
          }
        })
    },
    */
  },
}
</script>
<style scoped>
.scroller,
.scrolled {
  overflow-x: auto;
}
.scroller > div {
  height: 1px;
}
.scrolled div {
  display: inline-block; /*flex*/
}
</style>
